import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';

export class StaffUsersField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            (params) => {
                // Avoid error on initialising the model
                if (!params?.organisation_id) return [];
                // User hasn't department
                if (!params.departments) {
                    return [params];
                }
                return new Promise(function (resolve, reject) {
                    ApiService.query('users/department/colleagues', {
                        params: {
                            limit: 100,
                            pagination: true,
                            fields: 'first_name,last_name,user_id',
                        }
                    })
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch((error) => {
                        Errors.handle(error);
                        reject();
                    })
                });
            },
            (record) => {
                if (!record) {
                    return null;
                }
                return {
                    value: record.user_id,
                    text: `${record.first_name} ${record.last_name}`,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
