import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import { ResponseTemplatesField } from '@/modules/applications/fields/response-template-field';

const label = (name) => i18n.t(`APPLICATION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`APPLICATION.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    title: ResponseTemplatesField.relationToOne(
        'title',
        label('title'),
        { required: true },
        { placeholder: placeholder('title')},
        [],
        { asyncData: true, hasPagination: true }
    ),
    message: new StringField('message', label('message'), { required: true, }, { placeholder: placeholder('message') }),
    comments: new StringField('comments', label('comments'), { required: true, }, { placeholder: placeholder('comments') }),
};

export class ApplicationResponseModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
