<template>
    <st-modal ref='modal'
              hide-header-delimiter
              no-close-on-backdrop
              no-close-on-esc
              hide-footer
              hideable
              hide-header-close
              size="md"
              :title="$t('APPLICATION.CLARIFICATION_MODAL.TITLE')"
              :hideHeaderClose="isLoading"
              customClass="form-modal"
              :id="modalId"
    >
        <template #body>
            <form class="form"
                  novalidate="novalidate"
                  id="st_modal_clarification_form"
            >
                <div class="row pb-6">
                    <div class="col">
                        <div class="form-group">
                            <label>
                                {{ fields.comment.label }}
                            </label>
                            <b-form-textarea
                                :ref="fields.comment.name"
                                :name="fields.comment.name"
                                v-model="model[fields.comment.name]"
                                rows="10"
                            />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <st-button
                        size="large"
                        variant="secondary"
                        :callback="hide"
                        :disabled="isLoading"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        size="large"
                        variant="primary"
                        :callback="doSubmit"
                        :spinner="isLoading"
                    >
                        <span>{{ $t('GENERAL.BUTTON.SEND') }}</span>
                    </st-button>
                </div>
            </form>
        </template>
    </st-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { generateRandomId } from '@/core/helpers/globalMethods';
import { FormSchema } from '@/shared/form/form-schema';
import { ApplicationModel } from "@/modules/applications/models/application-model";
import { createFormValidation } from '@/shared/utils/create-form-validation';

const { fields } =  ApplicationModel;

const formSchema = new FormSchema([
   fields.comment,
]);

export default {
    name: 'ApplicationClarificationModal',
    props: {
        applicationId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
            modalId: `st-modal-clarification-${generateRandomId()}`,
        }
    },
    methods: {
        ...mapActions({
            doSendClarifications: 'applications/form/sendClarifications',
        }),
        show() {
            this.model = formSchema.initialValues({});
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const payload = {
                    id: this.applicationId,
                    comment: this.model.comment,
                }
                this.doSendClarifications(payload)
                .then(() => {
                    this.hide();
                });
            }
        },
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
        }),
        isLoading() {
            return this.loading['applications/sendClarifications'];
        }
    },
    async created() {
        this.model = formSchema.initialValues({});
    },
    mounted() {
        this.$root.$on('bv::modal::shown', (modalObj) => {
            if (modalObj.componentId === this.modalId) {
                this.fv = createFormValidation(this.modalId, this.rules);
            }
        });
    },
}
</script>
